@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Quicksand;
}

/* Fonts for Big letter headers */
.fontPrata {
  font-family: 'Prata', serif;
}

/* Section mini-headers */
.fontNothingYouCouldDo {
  font-family: 'Nothing You Could Do', sans-serif;
}



/* new nav */
.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #DDDAD2;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all .375s;
}

.main-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

.main-nav::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background: linear-gradient(to bottom, #b7a26d, #DDDAD2);
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all .275s .1s;
}

.main-nav.is-open::before {
   transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
   display: inline-flex;
   flex-direction: column;
   height: 93%; /* Should be 100%, but we have a notice message :D */
   align-items: flex-end;
   justify-content: center;
   transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
   display: block;
   margin: .5rem 0;
   text-align: right;
   transform: skew(16deg);
}

.main-nav button {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open button {
  opacity: 1;
  transform: translateY(0);
}

.main-nav button {
  display: block;
  padding: 12px 0;
  color: white;
  font-size: 2rem;
  text-decoration: none;
  font-weight: bold;
  font-family: Quicksand;
}

.open-main-nav {
  /* position: absolute; */
  top: 15px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
}

.open-main-nav:focus {
  outline: none;
}

.burger {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: black;
  transition: all .275s;
}

.burger:after,
.burger:before {
  content: '';
  display: block;
  height: 100%;
  background: black;
  transition: all .275s;
}

.burger:after {
  transform: translateY(-12px) skew(-20deg);
}

.burger:before {
transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .burger {
transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

/* MENU Text part */

.burger-text {
  display: block;
  font-size: .675rem;
  letter-spacing: .05em;
  margin-top: .5em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: #000000;
}

.container {
	position: absolute;
	top: 0; 
  right: 0;
	bottom: 0; 
  left: 0;
	overflow: hidden;
	/* background: linear-gradient(to bottom, #eee, #ddd); */
}

.nav * {
  box-sizing: border-box;
  transition: all .35s ease;
}

.nav span {
  padding: .35em .6em;
  /* color: #80ab85; */
  color: #2e4630;
  position: relative;
  text-decoration: none;
  box-shadow: 1px 1px 1px 1px #ccc;
  
}

.nav span:hover {
  color: white;
  box-shadow: 0px 0px 0px 0px #80ab85;
  /* box-shadow: 1px 0px 1px 1px #80ab85; */
}


#heroSection {
  background-image: url(./Assets/hero/outer-bg.png);
  background-size: 250% 170%;
  background-position: 50% 50%;
}




/* 
.spa-btn {
  justify-content: center;
  align-items: center;
  font-weight: 600;
  box-shadow: 1px 1px 1px 1px #0000003b;
  transition-duration: 300ms;
}

.spa-btn:hover {
  color: #bfd1c4;
  background-color: #566d5c;
  box-shadow: 1px 0px 0px 0px white;
}

#heroSection .spa-btn {
  width: 260px;
  height: 60px;
} */








.testimonial-container {
  height: 60px;
  overflow: scroll;
}

.testimonial-text {
  font-size: clamp(12px, calc(12px + 0.3vw), 18px);
} 

.testimonial-container::-webkit-scrollbar {
  display: none;
}

/* footer social media buttons */
section {
  font-size: 14px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

section a {
  /* display: inline-flex; */
  width: 36px;
  height: 36px;
  opacity: .75;
}

section article {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 6px 2px #8d8c8c;
  color: #fff;
  transform: perspective(300px) rotateY(0deg);
  transition: transform 0.4s linear, background-color 0s linear 0.2s, color 0s linear 0.2s;
}

section a:hover article {
  transform: perspective(300px) rotateY(180deg);
  color: #eee;
  transition: transform 0.2s linear, background-color 0s linear 0.1s, color 0s linear 0.1s;
}


a:nth-child(1) article {
  background-color: #3b5998;
}

a:nth-child(2) article {
  background-color: #c32aa3;
}

a:nth-child(3) article {
  background-color: #3399cc;
}


section article span {
  display:flex;
  justify-content:center;
  align-items: center;
  width: 36px;
  height: 36px;
  transition: transform 0s linear 0.2s, text-shadow 0s linear 0.2s;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
}

section a:hover span {
  transition: transform 0s linear 0.1s, text-shadow 0s linear 0.1s;
  transform: scale(-1, 1);
  text-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
}


@font-face {
  font-family: "SSSocial";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.eot");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.eot?#iefix") format("embedded-opentype"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.woff") format("woff"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.ttf") format("truetype"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.svg#SSSocialRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"] {
  -ms-zoom: 1;
}

.ss-icon,
.ss-icon.ss-social,
.ss-icon.ss-social-circle,
[class^="ss-"]:before,
[class*=" ss-"]:before,
[class^="ss-"][class*=" ss-social"]:before,
[class*=" ss-"][class*=" ss-social"]:before,
[class^="ss-"].right:after,
[class*=" ss-"].right:after,
[class^="ss-"][class*=" ss-social"].right:after,
[class*=" ss-"][class*=" ss-social"].right:after {
  font-family: "SSSocial";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.control-dots{
  display:none
}

.thumbs-wrapper {
  display: none;
}

.carousel-status{
  display:none
}

.carousel.carousel-slider .control-arrow{
  padding: 0px 11px !important;
  height: 40px !important;
  opacity: 1 !important;
  border-radius: 25px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.control-prev, .control-next{
  background: transparent !important;
  border: 1px solid #2E4630 !important;
  margin-top: 24px !important;
  /* margin-left: -24px; */
  /* margin-right: -24px; */
}

.carousel .control-prev.control-arrow:before {
  border-right :8px solid #2E4630 !important;
  /* margin-right: 3px; */
}

.carousel .control-next.control-arrow:before {
  border-left :8px solid #2E4630 !important;
  /* margin-right: 3px; */
}





.map-container {
  display: none;
}


@media only screen and (max-width: 410px)  {
  .service-container {
    width: 60%;
  }

}

@media only screen and (max-width: 410px)  {
  .service-title {
    font-size: 14px;
  }
  .service-btn {
    font-size: 10px;
  }
}


@media only screen and (min-width: 420px)  {
  .portrait-container {
    width: 85vw;
    margin: 0 auto;
  }
  .header-container {
    width: 80%;
    /* margin-top: 1rem !important; */
    margin: 0 auto;
  }
  #heroSection h1 {
    /* font-size:5.5rem !important; */
    line-height: 70px !important;
  }
  .form-container {
    padding-bottom: 6rem;
  }
  .map-container {
    display: flex;
  }
}

@media only screen and (min-width: 640px)  {
  .portrait-container {
    width: 85vw;
    margin-top: -6% !important;
  }
  .header-container {
    width: 70vw;
  }
  #heroSection h1 {
    font-size:5.5rem !important;
    line-height: 90px !important;
  }
}



@media only screen and (min-width: 768px)  {
  .portrait-container img {
    max-width: 200%;
    width: 130% !important;
    height: 150% !important;
    margin-top: -60px !important;
    margin-left: -50px !important;
  }
  
  section {
    font-size: 16px;
  }
  
  section a, section article span {
    padding-top: 2px;
    width: 44px;
    height: 44px;
    opacity: 1.0;
  }  

  a:nth-child(1) article,
  a:nth-child(2) article,
  a:nth-child(3) article {
    background-color: #2E4630;
  }

  a:nth-child(1):hover article {
    background-color: #3b5998;
    color: #dddad2;
  }
  
  a:nth-child(2):hover article {
    background-color: #c32aa3;
    color: #dddad2;
  }
  
  a:nth-child(3):hover article {
    background-color: #3399cc;
    color: #dddad2;
  }
}

@media only screen and (min-width: 1030px)  {
  #heroSection {
    background-size: 150% 210%;
    background-position: 50% 50%;
  }
  section {
    font-size: 20px;
  }

  .testimonial-container {
    height: 70px;
  }
  
  .testimonial-text {
    font-size: 16px;
  } 

  section a, section article span {
    width: 64px;
    height: 64px;
  }  
  .control-next{
    margin-right:15px !important;
    margin-left:15px !important;
  }
  .control-prev{
    margin-right:15px !important;
    margin-left:15px !important;
  }
}

@media only screen and (min-width: 1430px)  {
  .control-next{
    margin-top:10px !important
  }
  .control-prev{
    margin-top:10px !important
  }
}

@media only screen and (min-width: 2440px)  {
  .control-next{
    margin-top:-2px !important
  }
  .control-prev{
    margin-top:-2px !important
  }
}




/* Hero Mobile Sample 1 */
.square {
  z-index: 1;
  position: absolute;
  width: 3rem;
  height: 2.8rem;
}

.sq-green {
  background: #2E4630;
  opacity: 0.9;
}

.sq-lightg {
  background: #659769;
  border: 2px solid #659769;
}

.sq-transparent {
  z-index: 101;
  background: transparent;
  border: 2px solid #2E4630;
}

.pos1 {
  top:65%;
  left:3%;
}
.pos2 {
  top:62%;
  left:9%;
}

.pos3 {
  top:72%;
  left:20%;
}
.pos4 {
  top:75%;
  left:28%;
}

.pos5 {
  width:2.5rem;
  height:2.3rem;
  top:92%;
  left:1%;
}
.pos6 {
  z-index:102;
  width:2.6rem;
  height:2.6rem;
  top:94%;
  left:5%;
}

.pos7 {
  width:2.7rem;
  height:2.3rem;
  top:58%;
  left:27%;
}
.pos8 {
  z-index:102;
  width:2.5rem;
  height:2.3rem;
  top:56%;
  left:23%;
}

/* Hero mobile sample 2 */


